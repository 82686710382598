<template>
    <div>
        <div class="pc-container d-none d-sm-block">
            <div style="padding: 80px 120px 90px;background: #FFF9F7;">
                <div class="text-h4 primary-text text-center font-weight-bold">{{ $t('AppBar.SubMenu.Chain') }}<br /><br />
                {{ $t('Page.Chain.Desc1') }}
                </div>
                <!-- <div class="d-flex justify-space-between align-center mx-auto" style="max-width:1800px;margin-top:100px">
                    <div style="max-width:40%">
                        <div class="text-h5 font-weight-bold primary-text">● {{ $t('Page.Chain.Title1') }}</div>
                        <div class="mt-6">{{ $t('Page.Chain.Cont1') }}</div>
                    </div>
                    <div style="max-width:40%">
                        <v-img src="@/assets/images/chain_a.png"></v-img>
                    </div>
                </div> -->

                <div class="d-flex justify-space-between" style="margin-top:80px">
                    <v-card elevation="0" color="#AC8250" class="d-flex flex-column justify-space-between pa-5">
                        <div>
                            <div class="text-h6 font-weight-bold white--text">● {{ $t('Page.Chain.Title1') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Chain.Cont1') }}</div>
                        </div>
                        <!-- <div><img width="100%" src="@/assets/images/chain_a.png" class="d-block mx-auto" /></div> -->
                    </v-card>
                    <v-card elevation="0" color="#AC8250" class="d-flex flex-column justify-space-between pa-5 mx-5">
                        <div>
                            <div class="text-h6 font-weight-bold white--text">● {{ $t('Page.Chain.Title2') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Chain.Cont2') }}</div>
                        </div>
                        <!-- <div class="mt-5"><img width="70%" src="@/assets/images/chain_b.png" class="d-block mx-auto" /></div> -->
                    </v-card>
                    <v-card elevation="0" color="#AC8250" class="d-flex flex-column justify-space-between pa-5">
                        <div>
                            <div class="text-h6 font-weight-bold white--text">● {{ $t('Page.Chain.Title3') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Chain.Cont3') }}</div>
                        </div>
                        <!-- <div><img width="80%" src="@/assets/images/chain_c.png" class="d-block mx-auto" /></div> -->
                    </v-card>
                </div>
            </div>
            

            <!-- <div style="padding: 180px 120px 90px;background: #FDFFF7;">
                <div class="d-flex justify-space-between align-center mx-auto" style="max-width:1800px;">
                    <div style="max-width:40%">
                        <v-img src="@/assets/images/chain_b.png"></v-img>
                    </div>
                    <div style="max-width:40%">
                        <div class="text-h5 font-weight-bold primary-text">● {{ $t('Page.Chain.Title2') }}</div>
                        <div class="mt-6">{{ $t('Page.Chain.Cont2') }}</div>
                    </div>
                </div>
            </div>
            <div style="padding: 180px 120px 90px;background: #F8F4F0;">
                <div class="d-flex justify-space-between align-center mx-auto" style="max-width:1800px;">
                    <div style="max-width:40%">
                        <div class="text-h5 font-weight-bold primary-text">● {{ $t('Page.Chain.Title3') }}</div>
                        <div class="mt-6">{{ $t('Page.Chain.Cont3') }}</div>
                    </div>
                    <div style="max-width:40%">
                        <v-img src="@/assets/images/chain_c.png"></v-img>
                    </div>
                </div>
            </div> -->
            <Footer></Footer>
        </div>
    
        <!-- mobile -->
        <div class="mobile-container d-block d-sm-none">
            <div class="pt-10 pb-5 px-6" style="background: #FFF9F7;">
                <div class="text-h4 primary-text text-center font-weight-bold">{{ $t('AppBar.SubMenu.Chain') }}<br /><br /></div>
                <div class="primary-text text-h6 text-center pt-4">{{ $t('Page.Chain.Desc1') }}</div>
                <div class="primary-text text-body-1 font-weight-bold mt-10">● {{ $t('Page.Chain.Title1') }}</div>
                <div class="text-body-2 my-4">{{ $t('Page.Chain.Cont1') }}</div>
                <!-- <v-img width="100%" src="@/assets/images/chain_a.png" class="d-block"></v-img> -->
            </div>
            <div class="pt-15 pb-5 px-6" style="background: #FDFFF7;">
                <div class="primary-text text-body-1 font-weight-bold">● {{ $t('Page.Chain.Title2') }}</div>
                <div class="text-body-2 my-4">{{ $t('Page.Chain.Cont2') }}</div>
                <!-- <v-img width="100%" src="@/assets/images/chain_b.png" class="d-block"></v-img> -->
            </div>
            <div class="pt-15 pb-5 px-6" style="background: #F8F4F0;">
                <div class="primary-text text-body-1 font-weight-bold">● {{ $t('Page.Chain.Title3') }}</div>
                <div class="text-body-2 my-4">{{ $t('Page.Chain.Cont3') }}</div>
                <!-- <v-img width="100%" src="@/assets/images/chain_c.png" class="d-block"></v-img> -->
            </div>
            <Footer></Footer>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Footer from '../../components/Footer.vue'
  
  
  export default {
    name: 'Home',
    components: {
      Footer
    },
    mounted(){
    }
  }
  </script>
  
  <style lang="scss">
  
    .pc-container {
      min-width: 768px;
    }
  
    @media screen and (max-width: 960px) {
      .mobile-container {
        overflow: hidden;
      }
    }
  </style>
  